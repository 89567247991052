import React from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
  withStyles,
} from '@material-ui/core';
import { format, parseISO, parse } from 'date-fns';

function dateFormatter(value) {
  return value ? format(parseISO(value), 'dd/MM/yyyy') : null;
}

function timeFormatter(value) {
  return value
    ? format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm a')
    : 'Not specified';
}

function currencyFormatter(value) {
  return `£${formatNumber(value)}`;
}

function formatNumber(number) {
  return number.toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

const styles = {
  spacer: {
    minHeight: '50px',
  },
  header: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
  },
  image: {
    width: '100%',
  },
  textBlock: {
    whiteSpace: 'pre',
  },
  table: {
    tableLayout: 'auto',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  headerCol: {
    fontWeight: 'bold',
    verticalAlign: 'top',
  },
  detailCol: {
    width: '100%',
  },
};

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

class OrderSheet extends React.Component {
  render() {
    const { classes, data } = this.props;
    return (
      <Grid container spacing={3}>
        <style>{`@page {size: auto; margin: 20mm;}`}</style>
        <Grid item xs={12} className={classes.spacer} />
        <Grid item xs={9} className={classes.header}>
          <TableContainer>
            <Table
              size='small'
              className={classes.table}
              aria-label='order details'
            >
              <TableBody>
                <TableRow>
                  <TableCell className={classes.headerCol}>Company:</TableCell>
                  <TableCell className={classes.detailCol}>
                    {data.supplier.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.headerCol}>Contact:</TableCell>
                  <TableCell className={classes.detailCol}>
                    {data.supplierContact ? data.supplierContact.name : 'n/a'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.headerCol}>Date:</TableCell>
                  <TableCell className={classes.detailCol}>
                    {dateFormatter(data.orderDate)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.headerCol}>Order No:</TableCell>
                  <TableCell className={classes.detailCol}>
                    P{data.projectNumber}/{data.id}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={3}>
          <img
            src={process.env.PUBLIC_URL + '/hi-level_logo.png'}
            className={classes.image}
            alt='HiLevel Logo'
          />
          <br />
          <img
            src={process.env.PUBLIC_URL + '/hiLevelAddress.png'}
            className={classes.image}
            alt='HiLevel Address'
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Dear{' '}
            {data.supplierContact ? data.supplierContact.name : 'Sir/Madam'},
          </Typography>
          <br />
          <Typography className={classes.textBlock}>{data.note1}</Typography>
          <Typography className={classes.textBlock}>{data.note2}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              size='small'
              className={classes.table}
              aria-label='delivery details'
            >
              <TableBody>
                <TableRow>
                  <TableCell className={classes.headerCol}>
                    Delivery to:
                  </TableCell>
                  <TableCell className={classes.detailCol}>
                    <span className={classes.textBlock}>
                      {data.deliveryAddress}
                    </span>
                  </TableCell>
                </TableRow>
                {data.deliveryTime && (
                  <TableRow>
                    <TableCell className={classes.headerCol}>At:</TableCell>
                    <TableCell className={classes.detailCol}>
                      {timeFormatter(data.deliveryTime)}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell className={classes.headerCol}>On:</TableCell>
                  <TableCell className={classes.detailCol}>
                    {dateFormatter(data.deliveryDate)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.headerCol}>
                    All for the sum of:
                  </TableCell>
                  <TableCell className={classes.detailCol}>
                    {currencyFormatter(data.orderValue)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography>Please confirm.</Typography>
          <br />
          <Typography>Kind Regards,</Typography>
          <br />
          <Typography>{data.user ? data.user.name : 'Hi-Level'}</Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(OrderSheet);
