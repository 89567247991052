import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControlLabel,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { isValid, parseISO, subDays, addDays } from 'date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Checkbox } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import { Formik, Form, FieldArray, Field, useFormikContext } from 'formik';

const dateLeeway = 1; // days leeway from delivery date

const CopyField = (props) => {
  const {
    values: { orderDates, copyAll },
    touched,
    setFieldValue,
  } = useFormikContext();

  // If copy all is true, copy first date to other dates
  React.useEffect(() => {
    if (copyAll && touched.orderDates && orderDates[0]) {
      setFieldValue(props.name, orderDates[0].date);
    }
  }, [orderDates, copyAll, touched.orderDates, setFieldValue, props.name]);

  return <Field {...props} />;
};

const AmendDateDialog = ({ orders, onClose, onSubmit, open }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} scroll='paper'>
      <DialogTitle>Change Date</DialogTitle>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Formik
          initialValues={{
            copyAll: true,
            orderDates: orders.map((o) => ({
              order: o.id,
              date: parseISO(o.deliveryDate),
            })),
          }}
          validate={(values) =>
            // check all dates are valid
            values.orderDates.every((od) => isValid(od.date))
          }
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ values, isValid, isSubmitting, handleSubmit, dirty }) => (
            <>
              <DialogContent dividers={true}>
                <DialogContentText>
                  Request an alternative delivery date for {orders.length}{' '}
                  {orders.length > 1 ? 'orders' : 'order'}. Hi-Level will review
                  and confirm acceptance.
                </DialogContentText>
                <Form>
                  {values.orderDates.length > 1 && (
                    <FormControlLabel
                      control={
                        <Field
                          name='copyAll'
                          type='checkbox'
                          component={Checkbox}
                          color='primary'
                        />
                      }
                      label='Copy All'
                    />
                  )}
                  <FieldArray
                    name='orderDates'
                    render={() => (
                      <div>
                        {values.orderDates.map((od, index) =>
                          index > 0 ? (
                            <div key={`date-input${index}`}>
                              <CopyField
                                name={`orderDates.${index}.date`}
                                component={DatePicker}
                                disabled={values.copyAll}
                                disableToolbar
                                variant='inline'
                                format='dd/MM/yyyy'
                                margin='normal'
                                label={od.order}
                                autoOk={true}
                                minDate={subDays(od.date, dateLeeway)}
                                maxDate={addDays(od.date, dateLeeway)}
                              />
                            </div>
                          ) : (
                            <div key={`date-input${index}`}>
                              <Field
                                name={`orderDates.${index}.date`}
                                component={DatePicker}
                                disableToolbar
                                variant='inline'
                                format='dd/MM/yyyy'
                                margin='normal'
                                label={od.order}
                                autoOk={true}
                                minDate={subDays(od.date, dateLeeway)}
                                maxDate={addDays(od.date, dateLeeway)}
                              />
                            </div>
                          )
                        )}
                      </div>
                    )}
                  />
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  type='submit'
                  color='primary'
                  disabled={!dirty || !isValid || isSubmitting}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    </Dialog>
  );
};

export default AmendDateDialog;
