import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  withStyles,
  Typography,
  IconButton,
  ButtonGroup,
  DialogActions,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useReactToPrint } from 'react-to-print';
import Documents from '../Documents';
import OrderSheet from '../OrderSheet';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// custom dialog title with close button
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ActionRenderer = ({ node }) => {
  const [filesDialogOpen, setFilesDialogOpen] = React.useState(false);
  const [orderDialogOpen, setOrderDialogOpen] = React.useState(false);
  const orderSheetRef = React.useRef();
  const handlePrint = useReactToPrint({
    documentTitle: 'Purchase Order',
    content: () => orderSheetRef.current,
  });

  // don't render for group rows
  if (node.group) return null;

  return (
    <React.Fragment>
      <span>
        <ButtonGroup color='primary' size='small'>
          <Button onClick={() => setFilesDialogOpen(true)}>Files</Button>
          <Button onClick={() => setOrderDialogOpen(true)}>Order</Button>
        </ButtonGroup>
      </span>
      <Dialog
        open={filesDialogOpen}
        onClose={() => setFilesDialogOpen(false)}
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle onClose={() => setFilesDialogOpen(false)}>
          Files
        </DialogTitle>
        <DialogContent>
          <Documents purchaseOrderId={node.data.id} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={orderDialogOpen}
        onClose={() => setOrderDialogOpen(false)}
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle onClose={() => setOrderDialogOpen(false)}>
          Purchase Order
        </DialogTitle>
        <DialogContent>
          <OrderSheet data={node.data} ref={orderSheetRef} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrint} color='primary'>
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ActionRenderer;
