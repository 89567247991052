import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fab,
  makeStyles,
  Button,
  TextField,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Chat as ChatIcon } from '@material-ui/icons';
import { gql, useMutation } from '@apollo/client';

const CONTACT_PRODUCTION = gql`
  mutation contactProduction($message: String!) {
    contactProduction(message: $message)
  }
`;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ContactDialog = () => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [contactPm, { data }] = useMutation(CONTACT_PRODUCTION);

  React.useEffect(() => {
    if (data && data.contactProduction === 'OK') {
      setAlertOpen(true);
    }
  }, [data]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = () => {
    contactPm({ variables: { message } });
    setDialogOpen(false);
    setMessage('');
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <React.Fragment>
      <Fab
        color='primary'
        variant='extended'
        aria-label='contact us'
        className={classes.fab}
        onClick={handleDialogOpen}
      >
        <ChatIcon className={classes.extendedIcon} />
        Contact us
      </Fab>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Type your message below and press submit.
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='message'
            label='Message'
            multiline
            rows={4}
            required
            fullWidth
            value={message}
            onChange={handleMessageChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            disabled={message.length <= 0}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity='success'
          elevation={6}
          variant='filled'
        >
          Message sent!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default ContactDialog;
