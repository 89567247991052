import React from 'react';
import {
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TableHead,
  CircularProgress,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { gql, useMutation, useQuery } from '@apollo/client';
import DocumentViewer from './DocumentViewer';

const GET_PO_FILES = gql`
  query purchaseOrderFiles($id: Int!) {
    purchaseOrderFiles(filter: { purchaseOrderId: $id }) {
      id
      url
      fileName
      createdAt
      viewedAt
    }
  }
`;

const MARK_VIEWED = gql`
  mutation markViewed($id: ID!) {
    markPurchaseOrderFileViewed(id: $id) {
      id
    }
  }
`;

const formatDateTime = (date) => format(parseISO(date), 'dd/MM/yyyy h:mm a');

const Documents = (params) => {
  const { purchaseOrderId } = params;
  const [docViewerOpen, setDocViewerOpen] = React.useState(false);
  const [docTitle, setDocTitle] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');
  const [fileName, setFileName] = React.useState('');
  const { loading, error, data } = useQuery(GET_PO_FILES, {
    variables: { id: purchaseOrderId },
  });
  const [markViewed] = useMutation(MARK_VIEWED, {
    refetchQueries: ['purchaseOrderFiles'],
  });

  if (loading) return <CircularProgress color='inherit' />;
  if (error) return <p>Error :(</p>;
  const files = data.purchaseOrderFiles;

  const handleViewClick = (file, view = true) => {
    markViewed({ variables: { id: file.id } });
    if (view) {
      // open viewer
      setDocTitle(file.fileName);
      setFileUrl(file.url);
      setFileName(file.fileName);
      setDocViewerOpen(true);
    } else {
      // download
      axios
        .get(file.url, { responseType: 'blob' })
        .then(({ data }) => saveAs(data, file.fileName));
    }
  };

  const handleModalClose = () => {
    setDocViewerOpen(false);
  };

  const fileRows = () => {
    if (files.length < 1) {
      // show no docs available
      return (
        <TableBody>
          <TableRow>
            <TableCell>No files available.</TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <React.Fragment>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Uploaded</TableCell>
            <TableCell>Viewed</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => {
            const viewable = file.fileName.endsWith('.pdf');
            return (
              <TableRow key={file.url}>
                <TableCell component='th' scope='row'>
                  {file.fileName}
                </TableCell>
                <TableCell>{formatDateTime(file.createdAt)}</TableCell>
                <TableCell>
                  {file.viewedAt ? formatDateTime(file.viewedAt) : 'Not viewed'}
                </TableCell>
                <TableCell>
                  {viewable ? (
                    <Button
                      variant='contained'
                      onClick={() => handleViewClick(file)}
                    >
                      View/Download
                    </Button>
                  ) : (
                    <Button
                      variant='contained'
                      onClick={() => handleViewClick(file, false)}
                    >
                      Download
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label='project documents'>{fileRows()}</Table>
      </TableContainer>
      <DocumentViewer
        title={docTitle}
        file={fileUrl}
        fileName={fileName}
        open={docViewerOpen}
        onClose={handleModalClose}
      />
    </React.Fragment>
  );
};

export default Documents;
