import React from 'react';
import {
  Drawer,
  IconButton,
  Divider,
  List,
  useTheme,
  makeStyles,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Assignment as AssignmentIcon,
} from '@material-ui/icons';
import { useRecoilState } from 'recoil';
import ListItemLink from './ListItemLink';
import { drawerOpenState } from '../atoms';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

const NavDrawer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor='left'
      open={drawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <div
        role='presentation'
        onClick={handleDrawerClose}
        onKeyDown={handleDrawerClose}
      >
        <List>
          <ListItemLink
            to='/orders'
            primary='Orders'
            icon={<AssignmentIcon />}
          />
        </List>
      </div>
    </Drawer>
  );
};

export default NavDrawer;
