import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import OrderGrid from '../components/OrderGrid';
import ContactDialog from '../components/ContactDialog';

const GET_ORDERS = gql`
  query getOrders($pending: Boolean, $includeCancelled: Boolean) {
    supplierOrders(pending: $pending, includeCancelled: $includeCancelled) {
      id
      projectNumber
      orderValue
      orderDate
      deliveryDate
      deliveryTime
      deliveryAddress
      note1
      note2
      supplier {
        name
      }
      supplierContact {
        name
      }
      user {
        name
      }
      costCode {
        description
      }
      supplierAcceptedAt
      cancelled
    }
  }
`;

const APPROVE_ORDERS = gql`
  mutation approveOrders($ids: [Int!]!) {
    acceptPurchaseOrders(ids: $ids) {
      id
      supplierAcceptedAt
    }
  }
`;

const OrderList = () => {
  const [mode, setMode] = React.useState('pending');
  const [includeCancelled, setIncludeCancelled] = React.useState(false);
  const { error, data } = useQuery(GET_ORDERS, {
    variables: { pending: mode === 'pending', includeCancelled },
    fetchPolicy: 'network-only',
  });
  const [approveOrders] = useMutation(APPROVE_ORDERS);

  if (error) return <p>Error :(</p>;

  const onApprove = (orders) => {
    const ids = orders.map((o) => o.id);
    approveOrders({
      variables: { ids },
      refetchQueries: [{ query: GET_ORDERS }],
    });
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OrderGrid
            rowData={data ? data.supplierOrders : []}
            mode={mode}
            setMode={setMode}
            includeCancelled={includeCancelled}
            setIncludeCancelled={setIncludeCancelled}
            onApprove={onApprove}
          />
        </Grid>
      </Grid>
      <ContactDialog />
    </div>
  );
};

export default OrderList;
