import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  makeStyles,
  CssBaseline,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { version } from '../package.json';
import NavBar from './components/NavBar';
import NavDrawer from './components/NavDrawer';
import { useAuth0 } from './react-auth0-spa';
import history from './utils/history';
import PrivateRoute from './components/PrivateRoute';
import OrderList from './containers/OrderList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const App = () => {
  const { loading, getTokenSilently } = useAuth0();
  const classes = useStyles();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          supplierOrders: {
            merge: false,
          },
        },
      },
    },
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    name: 'Supplier Portal',
    version,
  });

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router history={history}>
        <ApolloProvider client={client}>
          <header>
            <NavBar />
            <NavDrawer />
          </header>
          <div className={classes.content}>
            <div className={classes.drawerHeader} />
            <Switch>
              <PrivateRoute path='/orders' component={OrderList} />
              <Redirect to='/orders' />
            </Switch>
          </div>
        </ApolloProvider>
      </Router>
    </div>
  );
};

export default App;
