import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Snackbar,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import { parseISO, format } from 'date-fns';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Alert } from '@material-ui/lab';
import { gql, useApolloClient } from '@apollo/client';
import ActionRenderer from './grid/ActionRenderer';
import AmendDateDialog from './AmendDateDialog';

const GET_ORDER_ITEMS = gql`
  query getOrderParts($poId: Int!) {
    orderParts(filter: { purchaseOrderId: $poId }) {
      cleatOrders {
        id
        description
        mark
        count
        length
      }
      joistOrders {
        id
        description
        mark
        count
        length
      }
      beamOrders {
        id
        description
        mark
        count
        length
      }
      columnOrders {
        id
        description
        mark
        count
        length
      }
    }
  }
`;

function dateFormatter(params, formatStr = 'dd/MM/yyyy') {
  return params.value ? format(parseISO(params.value), formatStr) : null;
}

function pNoFormatter(params) {
  return params.value ? `P${params.value}` : null;
}

function currencyFormatter(params) {
  return params.value ? `£${formatNumber(params.value)}` : null;
}

function formatNumber(number) {
  return number.toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function defaultGroupSortComparator(nodeA, nodeB) {
  if (nodeA.key < nodeB.key) {
    return -1;
  } else if (nodeA.key > nodeB.key) {
    return 1;
  } else {
    return 0;
  }
}

const OrderGrid = ({
  rowData,
  mode,
  setMode,
  includeCancelled,
  setIncludeCancelled,
  onApprove,
}) => {
  const agGrid = React.useRef(null);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [alertParams, setAlertParams] = React.useState({ open: false });
  const [amendDateDialogOpen, setAmendDateDialogOpen] = React.useState(false);
  const client = useApolloClient();
  const pending = mode === 'pending';

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
  };
  const columnDefs = [
    {
      headerName: 'Project No',
      field: 'projectNumber',
      valueFormatter: pNoFormatter,
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Order No',
      field: 'id',
      cellRenderer: 'agGroupCellRenderer',
      checkboxSelection: (params) => !params.node.group, // hide checkbox on group row
    },
    {
      headerName: 'Type',
      field: 'costCode.description',
    },
    {
      headerName: 'Value',
      field: 'orderValue',
      aggFunc: 'sum',
      valueFormatter: currencyFormatter,
    },
    {
      headerName: 'Ordered',
      field: 'orderDate',
      valueFormatter: dateFormatter,
    },
    {
      headerName: 'Delivery',
      field: 'deliveryDate',
      valueFormatter: dateFormatter,
    },
  ];
  if (!pending) {
    // additional columns for approved orders view
    columnDefs.push({
      headerName: 'Approved',
      field: 'supplierAcceptedAt',
      valueFormatter: (params) => dateFormatter(params, 'dd/MM/yyyy HH:mm a'),
    });
  }
  // add final actions column
  columnDefs.push({
    headerName: 'Actions',
    cellRenderer: 'myActionRenderer',
  });

  const autoGroupColumnDef = {
    cellRendererParams: { checkbox: true },
  };
  const frameworkComponents = {
    myActionRenderer: ActionRenderer,
  };
  const statusBar = {
    statusPanels: [
      {
        statusPanel: 'agSelectedRowCountComponent',
        align: 'left',
      },
    ],
  };

  const selectAllClick = () => {
    agGrid.current.api.selectAll();
  };

  const clearClick = () => {
    agGrid.current.api.deselectAll();
  };

  // const changeDateClick = () => {
  //   setAmendDateDialogOpen(true);
  // };

  const approveClick = () => {
    onApprove(selectedRows);
    setAlertParams({
      open: true,
      severity: 'success',
      message: `Approved ${selectedRows.length} orders!`,
    });
  };

  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const handleAlertClose = () => {
    setAlertParams({ open: false });
  };

  const handleCloseAmendDateDialog = () => {
    setAmendDateDialogOpen(false);
  };

  const handleSubmitAmendDateDialog = (value) => {
    setAmendDateDialogOpen(false);
    setAlertParams({
      open: true,
      severity: 'info',
      message: `Requested date amendment on ${selectedRows.length} orders.`,
    });
  };

  const handleModeChange = (event) => {
    setMode(event.target.value);
  };

  const handleIncludeCancelledChange = (event) => {
    setIncludeCancelled(event.target.checked);
  };

  const getRowStyle = ({ node }) => {
    // show cancelled POs in red
    if (!node.group && node.data.cancelled) {
      return { background: '#f44336' };
    }
  };

  const excelStyles = [
    {
      id: 'header',
      interior: {
        color: '#aaaaaa',
        pattern: 'Solid',
      },
    },
    {
      id: 'body',
      interior: {
        color: '#dddddd',
        pattern: 'Solid',
      },
    },
  ];

  const detailCellRendererParams = {
    detailGridOptions: {
      columnDefs: [
        {
          headerName: 'Section',
          field: 'description',
        },
        { field: 'mark' },
        { headerName: 'Qty', field: 'count' },
        { headerName: 'Length (mm)', field: 'length' },
        {
          headerName: 'Total (mm)',
          valueGetter: (params) => params.data.length * params.data.count,
        },
      ],
      defaultColDef: { sortable: true, resizable: true, filter: true, flex: 1 },
      excelStyles,
    },
    getDetailRowData: (params) => {
      client
        .query({
          query: GET_ORDER_ITEMS,
          variables: {
            poId: params.node.data.id,
          },
        })
        .then(({ data }) => {
          // flatten orders to a single array
          const orderItems = data.orderParts.reduce(
            (prev, op) => [
              ...prev,
              ...op.beamOrders,
              ...op.cleatOrders,
              ...op.columnOrders,
              ...op.joistOrders,
            ],
            []
          );
          params.successCallback(orderItems);
        });
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader
          title='Orders'
          subheader={
            <FormGroup row>
              <FormControl component='fieldset'>
                <RadioGroup
                  row
                  aria-label='mode'
                  name='mode'
                  value={mode}
                  onChange={handleModeChange}
                >
                  <FormControlLabel
                    value='pending'
                    control={<Radio color='primary' />}
                    label='Pending'
                  />
                  <FormControlLabel
                    value='approved'
                    control={<Radio color='primary' />}
                    label='Approved'
                  />
                </RadioGroup>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeCancelled}
                    onChange={handleIncludeCancelledChange}
                  />
                }
                label='Include Cancelled'
              />
            </FormGroup>
          }
        />
        <CardContent>
          <div
            className='ag-theme-alpine'
            style={{
              height: '700px',
              width: '100%',
            }}
          >
            <AgGridReact
              ref={agGrid}
              modules={AllModules}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={rowData}
              immutableData
              getRowNodeId={(n) => n.id}
              rowSelection='multiple'
              groupSelectsChildren
              suppressRowClickSelection
              onSelectionChanged={onSelectionChanged}
              masterDetail
              autoGroupColumnDef={autoGroupColumnDef}
              detailCellRendererParams={detailCellRendererParams}
              frameworkComponents={frameworkComponents}
              defaultGroupSortComparator={defaultGroupSortComparator}
              statusBar={statusBar}
              groupMultiAutoColumn
              getRowStyle={getRowStyle}
              suppressAggFuncInHeader
              excelStyles={excelStyles}
            ></AgGridReact>
          </div>
        </CardContent>
        <CardActions>
          <Button
            variant='contained'
            onClick={selectAllClick}
            disabled={selectedRows.length === rowData.length}
          >
            Select All
          </Button>
          <Button
            variant='contained'
            onClick={clearClick}
            disabled={selectedRows.length < 1}
          >
            Clear
          </Button>
          {pending && (
            <>
              {/* <Button
                variant='contained'
                color='primary'
                onClick={changeDateClick}
                disabled={selectedRows.length < 1}
              >
                Change date
              </Button> */}
              <Button
                variant='contained'
                color='primary'
                onClick={approveClick}
                disabled={selectedRows.length < 1}
              >
                Approve
              </Button>
            </>
          )}
        </CardActions>
      </Card>
      <AmendDateDialog
        orders={selectedRows}
        onClose={handleCloseAmendDateDialog}
        onSubmit={handleSubmitAmendDateDialog}
        open={amendDateDialogOpen}
      />
      <Snackbar
        open={alertParams.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertParams.severity}
          elevation={6}
          variant='filled'
        >
          {alertParams.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default OrderGrid;
